import React from 'react';
import { CSVLink } from 'react-csv';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import DefaultButton from '../buttons/DefaultButton';
import Download from '../../../assets/icons/Download';
import Utils from '../../../odinForgeService/Utils';
import OdinForgeService from '../../../odinForgeService/OdinForgeService';
import ReportName, { ReportIds } from '../../../odinForgeService/Enums/ReportName';
import { Tooltip } from "@mui/material";

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * CSVExportButton Component
 * This component is used to export data to a CSV file.
 * Link for this lib: https://www.npmjs.com/package/react-csv
 *
 * Example usage:
 *
 * ```jsx:
 *      <CSVExportButton
 *       data={data}
 *       fieldsForCSV={fieldsForCSV}
 *       reportName={reportName}
 *       startDate={startDate}
 *       endDate={endDate}
 *       namesEnum={namesEnum}
 *       disabled={!showTable}
 *     />
 * ```
 */

interface CSVExportButtonProps<TData, T4> {
  data: TData;
  fieldsForCSV: { [key: string]: string };
  namesEnum: { [key: string]: string };
  reportName?: string;
  startDate?: string | dayjs.Dayjs;
  endDate?: string | dayjs.Dayjs;
  disabledCSV?: boolean;
  transformDataToCSVFormat?: (data: TData, namesEnum: {
    [key: string]: string;
  }, fieldsForCSV: {
      [key: string]: string;
  }, 
  filters?: string[]
  ) => {
      [key: string]: string | number | T4;
  }[]
  filters?: string[];
}

const CSVExportButton= <TData, T4> ({
  data,
  fieldsForCSV,
  namesEnum,
  reportName,
  startDate,
  endDate,
  disabledCSV = true,
  transformDataToCSVFormat,
  filters,
}: CSVExportButtonProps<TData, T4>) => {
  async function sendToBackend() {
    const start_date = dayjs(startDate).format('YYYY-MM-DD');
    const end_date = dayjs(endDate).format('YYYY-MM-DD');

    const getReportKeyByValue = (value: string): keyof typeof ReportName | undefined => {
      return (Object.keys(ReportName) as Array<keyof typeof ReportName>).find(key => ReportName[key] === value);
    };

    const reportKey = reportName && getReportKeyByValue(reportName) as keyof typeof ReportIds;
    const report_id = reportKey ? ReportIds[reportKey] : undefined;
  
    if (reportName && startDate && endDate) {
      console.log('reportName', reportName);
      console.log('report_id', report_id);
      OdinForgeService.instance().saveCsvExport(report_id, filters, start_date, end_date, namesEnum)
        .then(async (result ) => {
          if (result && result.data.status === true) {
            Utils.instance().onSuccess('The report has been downloaded successfully');
          }
        })
        .catch(() => {
          Utils.instance().onFailure('An error occurred while saving the report');
        });
    } else {
      Utils.instance().onFailure('An error occurred while saving the report');
    }
  }
  
  if (!transformDataToCSVFormat) return null;
  const csvData = transformDataToCSVFormat(data, namesEnum, fieldsForCSV, filters);

  const filename = `${reportName}_${startDate}---${endDate}.csv`;

  const content = (
    <Tooltip
      title={disabledCSV ? "Unable to download CSV. Please select at least one filter" : "Download CSV"}
      placement={"bottom"}
      classes={{
        tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
        arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
      }}
      arrow
      enterTouchDelay={10}
      leaveTouchDelay={1000}
    >
      <span>
        <DefaultButton 
          disabled={disabledCSV} 
          className='h-10' 
          label={<Download fill={!disabledCSV ? '#0079FF' : '#677C9F'} />} 
          variant="outlined"
        />
      </span>
    </Tooltip>
  );

  return disabledCSV ? (
    <div style={{ cursor: 'not-allowed' }}>
      {content}
    </div>
  ) : (
    <CSVLink 
      data={csvData} 
      filename={filename} 
      onClick={sendToBackend}
      style={{ cursor: 'pointer' }}
    >
      {content}
    </CSVLink>
  );
};

export default CSVExportButton;
