
import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { namesEnum, namesMetricsTab, toggleOptions } from "./utils/config";
import FilterBarConversationReport from "../../../organism/filterbar/FilterBarConversationReport";
import ConversationReportTable from "./components/ConversationReportTable";
import { ConversationReportClonedData, ConversationReportData, ConversationReportDataResponse, FilterDataConversationReport, TabKey } from "./utils/types/conversation-report.types";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import PaginationState from "./utils/types/pagination.types";
import { UserContext } from "../../../../context/UserContext/UserContext";
import { SelectChangeEvent } from "@mui/material";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";

dayjs.extend(utc);
dayjs.extend(timezone);


interface ConversationReportInterface {
    clonedData: ConversationReportClonedData
    passData: (data: ConversationReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const ConversationReport: React.FC<ConversationReportInterface> = ({
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}): JSX.Element => {
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
    const [selectedGroupings] = React.useState(3)
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    // const params = new URLSearchParams();
    const [data, setData] = React.useState<ConversationReportData[]>(clonedData['data']?clonedData['data']:[]);
    const reportName = ReportName.ConversionReport;
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    ////////////////
    const [enableFiltersApplyButton, setEnableFiltersApplyButton] = useState(true);
    ////////////////
    const [ devicesNames, setDevicesNames ] = useState<string[]>(clonedData['devicesOptions']?clonedData['devicesOptions']:[])
    const [ browserNames, setBrowserNames ] = useState<string[]>(clonedData['browsersOptions']?clonedData['browsersOptions']:[])
    const [ locationsNames, setLocationsNames ] = useState<string[]>(clonedData['locationsOptions']?clonedData['locationsOptions']:[])
    const [ userAgentsNames, setUserAgentsNames ] = useState<string[]>(clonedData['userAgentsOptions']?clonedData['userAgentsOptions']:[])

    const [ devicesOptions , setDevicesOptions ] = useState<string[]>([])
    const [ browsersOptions, setBrowsersOptions ] = useState<string[]>([]);
    const [ locationsOptions , setLocationsOptions] = useState<string[]>([])

    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<ConversationReportData[]>([]);
    const [transformedNamesEnumCSV, setTransformedNamesEnumCSV] = useState<{ [key: string]: string }>({});

    const groupSequence = groupings;

    const reportConfigValues = useRef({ reportName, groupSequence });

    const tabs: TabKey[] = ['Details', 'Devices', 'Browser', 'Locations', 'User Agents'];
    const [activeTab, setActiveTab] = useState<TabKey>(clonedData['filterTab']?clonedData['filterTab']:'Details');

    const [selectedType, setSelectedType] = React.useState(clonedData['selectedTypeFraudToggle']?clonedData['selectedTypeFraudToggle']:'all');

    const [loaderKey, setLoaderKey] = useState(0);

    const [isFirstRender, setIsFirstRender] = React.useState(true);
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
    })

    const handleOptionSelectChange = (event: SelectChangeEvent<string[]>, type: 'devices' | 'browsers' | 'locations' | 'userAgents' ) => {

        const { target: { value } } = event;
        let selectedValues = typeof value === 'string' ? value.split(',') : value;
        const maxSelections = 5;

        if (selectedValues?.length > maxSelections) {
            selectedValues = selectedValues.slice(0, maxSelections);
            Utils.instance().onFailure(`You can select up to ${maxSelections} values`);
            return;
        }

        switch (type) {
            case 'devices':
                setDevicesNames(selectedValues)
                break;
            case 'browsers':
                setBrowserNames(selectedValues)
                break;
            case 'locations':
                setLocationsNames(selectedValues)
                break;
            case 'userAgents':
                setUserAgentsNames(selectedValues)
                break;
        }
    }

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        dateRange: dateRange,
        startDate: startDate,
        endDate: endDate,
        activeTab: activeTab,
        selectedType: selectedType,
        devicesNames: devicesNames,
        browserNames: browserNames,
        locationsNames: locationsNames,
        userAgentsNames: userAgentsNames,
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
            || activeTab !== clonedData['filterTab']
            || selectedType !== clonedData['selectedTypeFraudToggle']
            || JSON.stringify(devicesNames) !== JSON.stringify(clonedData['devicesOptions'])
            || JSON.stringify(browserNames) !== JSON.stringify(clonedData['browsersOptions'])
            || JSON.stringify(locationsNames) !== JSON.stringify(clonedData['locationsOptions'])
            || JSON.stringify(userAgentsNames) !== JSON.stringify(clonedData['userAgentsOptions'])
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.dateRange !== dateRange ||
            previousValues.startDate !== startDate ||
            previousValues.endDate !== endDate ||
            previousValues.activeTab !== activeTab ||
            previousValues.selectedType !== selectedType ||
            previousValues.devicesNames !== devicesNames ||
            previousValues.browserNames !== browserNames ||
            previousValues.locationsNames !== locationsNames ||
            previousValues.userAgentsNames !== userAgentsNames
        )) {
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                dateRange: dateRange,
                startDate: startDate,
                endDate: endDate,
                activeTab: activeTab,
                selectedType: selectedType,
                devicesNames: devicesNames,
                browserNames: browserNames,
                locationsNames: locationsNames,
                userAgentsNames: userAgentsNames,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, startDate, endDate, activeTab, selectedType, devicesNames, browserNames, locationsNames, userAgentsNames]);


    function generateParams() {
        const params = new URLSearchParams();

        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })

        if (activeTab !== 'Details') {
            params.append('filters[0]', namesMetricsTab[activeTab]);
        }

        if (devicesNames.length > 0) {
            devicesNames.forEach(device => {
                params.append('conversions_device_types[]', device);
            });
        }
        if (browserNames.length > 0) {
            browserNames.forEach(browser => {
                params.append('conversions_browsers[]', browser);
            });
        }
        if (locationsNames.length > 0) {
            locationsNames.forEach(location => {
                params.append('conversions_geo_countries[]', location);
            });
        }
        params.append('currentPage', pagination.pageIndex?.toString());
        params.append('pageSize', pagination.pageSize?.toString());

        params.append('timezone', timezone) 

        if (isSuperAdmin && superTenant) {
                superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
                tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }

        // if (selectedType === 'all') params.append('conversions_fraud', '0') 
        if (selectedType === 'fraud') params.append('conversions_fraud', "1")

        return params;
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 59).format('YYYY-MM-DD HH:mm:ss'),
            dateRange: dateRange,
            filterTab: activeTab,
            selectedTypeFraudToggle: selectedType,
            devicesOptions: devicesNames,
            browsersOptions: browserNames,
            locationsOptions: locationsNames,
            userAgentsOptions: userAgentsNames,
        }

        return dataToHash;
    }

    async function handleBlur() {
        setTableLoading(true);
        const params = generateParams()!
        setData([]);
        const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
        if (cachedData){
            setTableLoading(false);
            setData(cachedData)
        } else {
            const isDetailsActiveTab = activeTab === 'Details';
            OdinForgeService.instance().getConversationReportData(params, isDetailsActiveTab, clonedReportId)
                .then(async (result: {data: ConversationReportDataResponse}) => {
                    setTableLoading(false);
                    if (result && result?.data?.status !== 'error') {
                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: result.data.current_page,
                            pageSize: result.data.per_page,
                            totalPages: result.data.last_page,
                            totalItems: result.data.total,
                        }));
                        setData(result.data.data);

                        createHash(generateObjectToHash, result.data.data, reportName)
                        // Utils.instance().createHash(generateObjectToHash(), result.data, reportName)
                    } else {
                        setTableLoading(false);
                        setData([]);
                        // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                    }
                })
                .catch((error) => {
                    if (error.code === 'ERR_CANCELED') {
                        setLoaderKey(prevKey => prevKey + 1);
                        console.log('Request canceled');
                    } else {
                        setTableLoading(false);
                        Utils.instance().onFailure('An error occurred while fetching the report');
                    }
                });
            }
    }

    const handlePageChange = (newPageIndex: number) => {
        setPagination((prev) => ({
          ...prev,
          pageIndex: newPageIndex + 1,
        }));
      };
    
      const handlePageSizeChange = (newPageSize: number) => {
        setPagination((prev) => ({
          ...prev,
          pageIndex: 1,
          pageSize: newPageSize,
        }));
      };

      useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }

        handleBlur();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [pagination.pageIndex, pagination.pageSize, activeTab]);

      const isMobile = useMediaQuery('(max-width: 1023px)')

      useEffect(() => {
        if (isMobile) {
            if (isFirstRender) {
                setIsFirstRender(false);
                return;
              }
              handleBlur();
        }
       
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [locationsNames, devicesNames, browserNames]);
      

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
                filterTab: activeTab,
                selectedTypeFraudToggle: selectedType,
                devicesOptions: devicesNames,
                browsersOptions: browserNames,
                locationsOptions: locationsNames,
                userAgentsOptions: userAgentsNames,
            },
            report_name: reportName
        }
    }
    
    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
            filterTab: activeTab,
            selectedTypeFraudToggle: selectedType,
            devicesOptions: devicesNames,
            browsersOptions: browserNames,
            locationsOptions: locationsNames,
            userAgentsOptions: userAgentsNames,
        }
        passData(dataToPass)
    }

    const filterConfig = [
        { label: "Devices", name: "devicesNames", options: devicesOptions, itemName: devicesNames, handleSelectChange: (event: SelectChangeEvent<string[]>) => handleOptionSelectChange(event, 'devices') },
        { label: "Browsers", name: "browserNames", options: browsersOptions, itemName: browserNames, handleSelectChange: (event: SelectChangeEvent<string[]>) => handleOptionSelectChange(event, 'browsers') },
        { label: "Locations", name: "locationsNames", options: locationsOptions, itemName: locationsNames, handleSelectChange: (event: SelectChangeEvent<string[]>) => handleOptionSelectChange(event, 'locations') },
    ];

    const clearOptions = () => {
        setDevicesNames([])
        setBrowserNames([])
        setLocationsNames([])
        setUserAgentsNames([])
    }

    async function getFilterData() {
        const formattedStartDate = formatLocalISO((startDate as Dayjs).set('second', 0o0));
        const formattedEndDate = formatLocalISO((endDate as Dayjs).set('second', 59));

        const fetchData = () => {
            return OdinForgeService.instance().getConversionReportFilterData(formattedStartDate, formattedEndDate, timezone, `${clonedReportId}-filters` , isSuperAdmin, superTenant, tenantId)
        }

        try {
            const response = await fetchData()
           

            function transformData(inputData: FilterDataConversationReport): FilterDataConversationReport {
                const transformedData: FilterDataConversationReport = {
                    browser: [],
                    device_type: [],
                    location: [],
                };
    
                for (const key in inputData) {
                    if (inputData.hasOwnProperty(key)) {
                        transformedData[key as keyof FilterDataConversationReport] = Array.from(
                            new Set(Object.values(inputData[key as keyof FilterDataConversationReport]))
                        );
                    }
                }
    
                return transformedData;
            }
            const data = transformData(response.data as FilterDataConversationReport);

            setDevicesOptions(data.device_type)
            setBrowsersOptions(data.browser)
            setLocationsOptions(data.location)

            /*
            When we get new filter data, check if the selected options are still present in the filters.
            We don't want to have an option selected that is not present in the dropdown.
            */

            const devicesNameFiltered = devicesNames ? devicesNames.filter((name) => data.device_type.includes(name)) : [];
            const browserNameFiltered = browserNames ? browserNames.filter((name) => data.browser.includes(name)) : [];
            const locationsNameFiltered = locationsNames ? locationsNames.filter((name) => data.location.includes(name)) : [];

            setDevicesNames(devicesNameFiltered);
            setBrowserNames(browserNameFiltered);
            setLocationsNames(locationsNameFiltered);
        } catch (error) {
            // @ts-ignore
            if (error?.code === 'ERR_CANCELED') {
                setLoaderKey(prevKey => prevKey + 1);
            }
            // Utils.instance().onFailure("Error fetching graph data");
        } finally {
            handleBlur();
        }
    } 

    useEffect(() => {
        getFilterData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateRange, superTenant, selectedType]);

    const handleTabClick = (tab: TabKey) => {
        setActiveTab(tab);
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
          }));
    };

    const handleChange = (newValue: string) => {
        setSelectedType(newValue);
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
          }));
    };

    useEffect(() => {
        
        let isFilterSelected = (devicesNames?.length > 0 || browserNames?.length > 0 || locationsNames?.length > 0 || userAgentsNames?.length > 0);
        setEnableFiltersApplyButton(isFilterSelected);

        setGroupings([...devicesNames, ...browserNames, ...locationsNames, ...userAgentsNames])
    }, [devicesNames, browserNames, locationsNames, userAgentsNames, startDate, endDate,]);
    
    return (
        <div className={"pb-10"} >
            <div className="!p-6 tablet-size:!p-0">
            <FilterBarConversationReport
                handleBlur={handleBlur}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectedGroupings={selectedGroupings}
                copyButton={copyButton}
                freezeButton={freezeButton}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                title={ReportName.ConversionReport}
                //////////////////////// for CSV btn
                data={transformedTableDataCSV}
                fieldsForCSV={transformedNamesEnumCSV}
                disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
                namesEnum={transformedNamesEnumCSV}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                ////////////////////////
                clearOptions={clearOptions}
                filterConfig={filterConfig}
                enableFiltersApplyButton={enableFiltersApplyButton}
                getFilterData={getFilterData}
                //////////////////////// FilterTabs Props
                tabs={tabs}
                activeTab={activeTab} 
                handleTabClick={handleTabClick}
                //////////////////////// Toggle button Props
                selectedType={selectedType}
                handleChange={handleChange}
                toggleOptions={toggleOptions}
                isFraudToggle={true}
                //////////////////////// ChartSelectDropdown Props
                isDisableNotNeeded={true}
                isNotNeedFetchData={true}
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
            />
            </div>
            {
                data && data.length !== 0 && pagination ? (
                    <ConversationReportTable data={data} onPageChange={handlePageChange} paginationState={pagination} onPageSizeChange={handlePageSizeChange} fraudToggleValue={selectedType} activeTab={activeTab} setTransformedTableDataCSV={setTransformedTableDataCSV} setTransformedNamesEnumCSV={setTransformedNamesEnumCSV} />
                ) : (
                    <div className="mt-6">
                        <DefaultTableMessage
                            key={loaderKey}
                            tableLoading={tableLoading}
                            state={
                                'noData'
                            }
                        />
                    </div>
                )
            }
            
        </div>
    );
}

export default ConversationReport