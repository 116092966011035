import React from 'react'; 
import { createColumnHelper } from "@tanstack/react-table";
import { ConversationReportData } from "./types/conversation-report.types";
import FraudStatus from "../components/FraudStatus";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';
dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
    'Devices': 'device_type',
    'Browsers': 'browsers',
    'Locations': 'locations',
    'User Agents': 'user_agent',
};

export const names = [
    'Devices',
    'Browsers',
    'Locations',
    'User Agents'
];

export const toggleOptions = [
  { value: 'all', label: 'All' },
  { value: 'fraud', label: 'Fraud', colorLight: '#E74C3C', colorDark: '#FDB5AE'},
];

export const namesMetricsTab: { [key: string]: string } = {
    'Devices': 'device_type',
    'Browser': 'browser',
    'Locations': 'geo_country',
    'User Agents': 'user_agent',
}

const columnHelper = createColumnHelper<ConversationReportData>()

export const tableColumns = [
  columnHelper.group({
    id: 'potential_fraud_group',
    header: () => "",
    columns: [
      {
        accessorKey: 'potential_fraud',
        header: '',
        id: 'potential_fraud',
        cell: info => <FraudStatus level={info.getValue() as 0 | 2 | 1} />,
        meta: { 
          isFraudRow: true,
        },
        enableSorting: false,
      },
    ],
  }),
  // Details
  columnHelper.group({
    id: 'Initial',
    header: () => "Initial",
    meta: { 
      isLastInGroup: true,
      isFontBold: true,
    },
    columns: [
      {
        accessorKey: 'click_id',
        header: 'Click id',
        id: 'click_id',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'ip_address',
        header: 'IP Address',
        id: 'ip_address',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'geo_country',
        header: 'GEO',
        id: 'geo_country',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'geo_state',
        header: 'State',
        id: 'geo_state',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'campaign_name',
        header: 'Campaign',
        id: 'campaign_name',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
        meta: { isLastInGroup: true },
      },
    ],
  }),
  columnHelper.group({
    id: 'Aff',
    header: () => "Aff",
    meta: { 
      isLastInGroup: true,
      isFontBold: true,
    },
    columns: [
      {
        accessorKey: 'aff_id',
        header: 'ID',
        id: 'aff_id',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'c1',
        header: 'C1',
        id: 'c1',
        cell: info => info.getValue() ? info.getValue() !== '' ? info.getValue() : '---' : '---',
      },
      {
        accessorKey: 'c2',
        header: 'C2',
        id: 'c2',
        cell: info => info.getValue() ? info.getValue() !== '' ? info.getValue() : '---' : '---',
      },
      {
        accessorKey: 'c3',
        header: 'C3',
        id: 'c3',
        cell: info => info.getValue() ? info.getValue() !== '' ? info.getValue() : '---' : '---',
      },
      {
        accessorKey: 'c4',
        header: 'C4',
        id: 'c4',
        cell: info => info.getValue() ? info.getValue() !== '' ? info.getValue() : '---' : '---',
      },
      {
        accessorKey: 'c5',
        header: 'C5',
        id: 'c5',
        cell: info => info.getValue() ? info.getValue() !== '' ? info.getValue() : '---' : '---',
        meta: { isLastInGroup: true },
      },

    ],
  }),
  columnHelper.group({
    id: 'Order',
    header: () => "Order",
    meta: { 
      isLastInGroup: true,
      isFontBold: true,
    },
    columns: [
      {
        accessorKey: 'order_id',
        header: 'Order ID',
        id: 'order_id',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'order_status',
        header: 'Status',
        id: 'order_status',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
        meta: { isLastInGroup: true },
      },
    ],
  }),
  columnHelper.group({
    id: 'Device',
    header: () => "Device",
    meta: { 
      isLastInGroup: true,
      isFontBold: true,
    },
    columns: [
      {
        accessorKey: 'device_type',
        header: 'Type',
        id: 'device_type',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? capitalizeSpecificWords(info.getValue()) : '---',
      },
      {
        accessorKey: 'device_os',
        header: 'Device OS',
        id: 'device_os',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? capitalizeSpecificWords(info.getValue()) : '---',
        meta: { isLastInGroup: true },
      },
    ],
  }),
  columnHelper.group({
    id: 'Browser',
    header: () => "Browser",
    meta: { 
      isLastInGroup: true,
      isFontBold: true,
    },
    columns: [
      {
        accessorKey: 'browser',
        header: 'Name',
        id: 'browser',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? capitalizeSpecificWords(info.getValue()) : '---',
      },
      {
        accessorKey: 'browser_version',
        header: 'Version',
        id: 'browser_version',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'user_agent',
        header: 'User Agent',
        id: 'user_agent',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
        meta: { isLastInGroup: true },
      },
    ],
  }),
  columnHelper.group({
    id: 'Fields',
    header: () => "Fields",
    meta: { 
      isFontBold: true,
    },
    columns: [
      {
        accessorKey: 'pasted_fields',
        header: 'Pasted',
        id: 'pasted_fields',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
      {
        accessorKey: 'autofill_fields',
        header: 'Autofill',
        id: 'autofill_fields',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
    ],
  }),
  // Devices
  columnHelper.group({
    id: 'Metric_Device',
    header: () => "Device",
    meta: { 
      isFontBold: true,
      isLastInGroup: true,
    },
    columns: [
      {
        accessorKey: 'device_type',
        header: 'Type',
        id: 'device_type',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? capitalizeSpecificWords(info.getValue()) : '---',
      },
    ],
  }),

  // Browser
  columnHelper.group({
    id: 'Metric_Browser',
    header: () => "Browser",
    meta: { 
      isFontBold: true,
      isLastInGroup: true,
    },
    columns: [
      {
        accessorKey: 'browser',
        header: 'Name',
        id: 'browser',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? capitalizeSpecificWords(info.getValue()) : '---',
      },
    ],
  }),

  // Locations
  columnHelper.group({
    id: 'Metric_Location',
    header: () => "Location",
    meta: { 
      isFontBold: true,
      isLastInGroup: true,
    },
    columns: [
      {
        accessorKey: 'geo_country',
        header: 'GEO',
        id: 'geo_country',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
    ],
  }),

  // User_Agents
  columnHelper.group({
    id: 'Metric_User_Agents',
    header: () => "Browser",
    meta: { 
      isFontBold: true,
      isLastInGroup: true,
    },
    columns: [
      {
        accessorKey: 'user_agent',
        header: 'User Agent',
        id: 'user_agent',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
      },
    ],
  }),

  // Metric Initial
  columnHelper.group({
    id: 'Metric_Initial',
    header: () => "Initial",
    meta: { 
      isFontBold: true,
    },
    columns: [
      {
        accessorKey: 'clicks',
        header: 'Clicks',
        id: 'clicks_id',
        cell: info => info.getValue() !== "NaN" && info.getValue() ? info.getValue() : '---',
        meta: { isAlignTextRight: true },
      },
      {
        accessorKey: 'partials',
        header: 'Partials',
        id: 'partials_id',
        cell: info => {
          const row = info.row.original;
          const partials = row.partials ?? '---';
          const percentage = Number(row.partials_percentage) && row?.partials_percentage ? (`${parseFloat(row?.partials_percentage).toFixed(2)}%`)  : '0.00%';
          return `${partials} / ${percentage}`;
        },
        meta: { isAlignTextRight: true },
      },
      {
        accessorKey: 'declines',
        header: 'Declines',
        id: 'declines_id',
        cell: info => {
          const row = info.row.original;
          const declines = row.declines ?? '---';
          const percentage = Number(row.declines_percentage) && row?.declines_percentage ? (`${parseFloat(row?.declines_percentage).toFixed(2)}%`)  : '0.00%';
          return `${declines} / ${percentage}`;
        },
        meta: { isAlignTextRight: true },
      },
      {
        accessorKey: 'sales',
        header: 'Sales',
        id: 'sales_id',
        cell: info => {
          const row = info.row.original;
          const sales = row.sales ?? '---';
          const percentage = Number(row.sales_percentage) && row?.sales_percentage ? (`${parseFloat(row?.sales_percentage).toFixed(2)}%`)  : '0.00%';
          return `${sales} / ${percentage}`;
        },
        meta: { isLastInGroup: false, isAlignTextRight: true },
      },
    ],
  }),


  ]