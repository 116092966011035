
import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../../../css/EmptyReport.css';
import { SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import '../../../../css/PaceReport.css';
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import FilterBarChart from '../../../organism/filterbar/FilterBarChart';
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';
import FilterBarChartSceleton from '../../../organism/filterbar/sceletons/FilterBarChartSceleton';
import TableLoading from '../../../UI/loading/TableLoading';
import transformData from './transformData';
import LineChart from '../../../molecules/LineChart/LineChart';
import {checkDataForNaN} from "../../../../util";
import { rangeConfigs } from './utils/config';
import { createHash } from '../../../../helpers/createHash';
import { UserContext } from '../../../../context/UserContext/UserContext';
import { LineChartClonedData, LineChartData, LineChartPrefixes } from './types/line-chart.types';
import { formatLocalISO } from '../../../../helpers/formatLocalISO';

dayjs.extend(utc);
dayjs.extend(timezone);

const mockData = {
    "current": [
        {
            "US": 4,
            "hour": 0
        },
        {
            "US": 2,
            "hour": 1
        },
        {
            "US": 2,
            "hour": 2
        },
        {
            "US": 2,
            "hour": 3
        },
        {
            "US": 1,
            "hour": 4
        },
        {
            "US": 1,
            "hour": 5
        },
        {
            "US": 1,
            "hour": 7
        },
        {
            "US": 1,
            "hour": 8
        }
    ],
    "previous": []
}

interface Props {
    clonedData: LineChartClonedData;
    passData: (data: LineChartClonedData) => void;
    passId: (id: string | number | null | undefined) => void;
    reportName: string
    reportType: 'salesPerformance' | 'salesPace'
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const SalesPerformanceReportLineChart: React.FC<Props> = ({
    clonedData,
    passData,
    passId,
    reportName,
    reportType,
    setRefreshKey,
    refreshKey
}: Props) => {
    const { theme, themedColors } = useContext(ThemeContext)!;
    const { timezone: userTimezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const emptyArray: string[] = [];
    const [geoName, setGeoName] = useState<string[]>(clonedData?.geo_name ? clonedData.geo_name : []);
    const [affiliateName, setAffiliateName] = useState<string[]>(clonedData.affiliate_name ?? []);
    const [offerName, setOfferName] = useState<string[]>(clonedData.offer_name ?? []);
    const [startDate, setStartDate] = useState<string | Dayjs>(clonedData['start_date'] ? dayjs.tz(clonedData['start_date']) : dayjs.tz().startOf('day'));
    const [endDate, setEndDate] = useState<string | Dayjs>(clonedData['end_date'] ? dayjs.tz(clonedData['end_date']) : dayjs.tz().endOf('day'));
    const [geoOptions, setGeoOptions] = useState<string[]>([]);
    const [affiliateOptions, setAffiliateOptions] = useState<string[]>([]);
    const [offerOptions, setOfferOptions] = useState<string[]>();
    const [previousData, setPreviousData] = useState<LineChartData[] | null>(null);
    const [currentData, setCurrentData] = useState<LineChartData[] | null>(null);
    const [dateRange, setDateRange] = useState<number>(clonedData.date_range ? clonedData.date_range : 0);
    const [dataKeys, setDataKeys] = useState<string[]>([]);
    const [datesApplyButton, setDatesApplyButton] = useState(true);
    const [enableFiltersApplyButton] = useState(true);
    const [datesPreviousStartDate, setDatesPreviousStartDate] = useState(startDate);
    const [datesPreviousEndDate, setDatesPreviousEndDate] = useState(endDate);
    const [copyButton, setCopyButton] = useState(!(clonedData));
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState(themedColors.gray.light);
    const [disableButton, setDisableButton] = useState(false);
    const [iconFillColor, setIconFillColor] = useState(themedColors.gray.bluish_light);
    const [componentCount] = useState<number>(0);
    const [clonedReportId] = useState(clonedData.id ? clonedData.id : 0);
    const [deletePopup, setDeletePopup] = useState(false);
    const [crmType, setCrmType] = useState('swiss');
    const [showDelete] = useState(clonedData.type === 'cloned' ? clonedData.type === 'cloned' : false);
    const [isDataFromBE, setIsDataFromBE] = useState(true);
    const [isFilterData, setIsFilterData] = useState(true);
    const [isLineChartData, setIsLineChartData] = useState(true);

    const isInitialLoad = useRef(true);
    const [ errorMessage, setErrorMessage ] = useState<string>('');

    const [legendGeoName, setLegendGeoName] = useState<string[]>(emptyArray);
    const [legendAffiliateName, setLegendAffiliateName] = useState<string[]>(emptyArray);
    const [legendOfferName, setLegendOfferName] = useState<string[]>(emptyArray);

    const [loaderKey, setLoaderKey] = useState(0);

    const createDataKeys = () => {
        const dataKeys: string[] = []
        geoName.forEach((value) => dataKeys.push(value))
        affiliateName.forEach((value) => dataKeys.push(value))
        offerName.forEach((value) => dataKeys.push(value))
        
        if (dataKeys.length === 0) {
            dataKeys.push('total')
        }

        return dataKeys
    }

    const saveFilters = async () => {
        await OdinForgeService.instance().postLineChartConfig(reportName,geoName, dateRange, affiliateName,offerName,true,clonedReportId, startDate, endDate);
    }

    const handleGeoSelectChange = (event: SelectChangeEvent<typeof geoName>) => {
        const { target: { value } } = event;
        let selectedGeos = typeof value === 'string' ? value.split(',') : value;
        const maxSelections = 5;

        if (selectedGeos?.length > maxSelections) {
            selectedGeos = selectedGeos.slice(0, maxSelections);
            Utils.instance().onFailure(`You can select up to ${maxSelections} geographies.`);
            return;
        }

        setGeoName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleAffiliateSelectChange = (event: SelectChangeEvent<typeof affiliateName>) => {
        const { target: { value } } = event;
        let selectedAffiliates = typeof value === 'string' ? value.split(',') : value;
        const maxSelections = 5;

        if (selectedAffiliates?.length > maxSelections) {
            selectedAffiliates = selectedAffiliates.slice(0, maxSelections);
            Utils.instance().onFailure(`You can select up to ${maxSelections} affiliates.`);
            return;
        }

        setAffiliateName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleOfferSelectChange = (event: SelectChangeEvent<typeof offerName>) => {
        const { target: { value } } = event;
        let selectedOffers = typeof value === 'string' ? value.split(',') : value;
        const maxSelections = 5;

        if (selectedOffers?.length > maxSelections) {
            selectedOffers = selectedOffers.slice(0, maxSelections);
            Utils.instance().onFailure(`You can select up to ${maxSelections} offers.`);
            return;
        }

        setOfferName(typeof value === 'string' ? value.split(',') : value);
    };

    const getFilterData = async () => {
        // const formattedStartDate = dayjs(startDate).set('second', 0).format('YYYY-MM-DDTHH:mm:ss');
        const formattedStartDate = formatLocalISO((startDate as Dayjs).set('second', 0o0))

        // const formattedEndDate = dayjs(endDate).set('second', 0).format('YYYY-MM-DDTHH:mm:ss');
        const formattedEndDate = formatLocalISO((endDate as Dayjs).set('second', 0o0))

        const fetchData = (type: 'salesPerformance' | 'salesPace') => {
            if (type === 'salesPerformance') {
                return OdinForgeService.instance().getSalesPerformanceGraphFilterData(formattedStartDate, formattedEndDate, userTimezone, tenantId, `${clonedReportId}-filters` , isSuperAdmin, superTenant);
            } else if (type === 'salesPace') {
                return OdinForgeService.instance().getSaleGraphFilterData(formattedStartDate, formattedEndDate, userTimezone, tenantId, `${clonedReportId}-filters`, isSuperAdmin, superTenant);
            }
        }

        try {
            setIsFilterData(true);
            const response = await fetchData(reportType)

            const responseData = response?.data;
            if (reportType === 'salesPerformance') {
                setGeoOptions(responseData.geo);
                setAffiliateOptions(responseData.affiliate_name);
                setOfferOptions(responseData.funnel_name);
                setDatesApplyButton(true);

                /*
                When we get new filter data, check if the selected options are still present in the filters.
                We don't want to have an option selected that is not present in the dropdown.
                 */
                const geoNameFiltered = geoName ? geoName.filter((item) => responseData.geo.includes(item)) : []
                const affiliateNameFiltered = affiliateName ? affiliateName.filter((item) => responseData.affiliate_name.includes(item)) : []
                const offerNameFiltered = offerName ? offerName.filter((item) => responseData.funnel_name.includes(item)) : []


                setGeoName(geoNameFiltered);
                setAffiliateName(affiliateNameFiltered);
                setOfferName(offerNameFiltered);
            } else if (reportType === 'salesPace') {
                setGeoOptions(responseData.geo);
                setAffiliateOptions(responseData.affiliate);
                setOfferOptions(responseData.funnels);
                setDatesApplyButton(true);

                const geoNameFiltered = geoName ? geoName.filter((value) => responseData.geo.includes(value)) : [];
                const affiliateNameFiltered = affiliateName ? affiliateName.filter((value) => responseData.affiliate.includes(value)) : [];
                const offerNameFiltered = offerName ? offerName.filter((item) => responseData.funnels.includes(item)) : []
                setGeoName(geoNameFiltered);
                setAffiliateName(affiliateNameFiltered);
                setOfferName(offerNameFiltered);
            }

        } catch (error) {
            // @ts-ignore
            if (error?.code === 'ERR_CANCELED') {
                setLoaderKey(prevKey => prevKey + 1);
                console.log('Request canceled');
            } else {
                Utils.instance().onFailure("Error fetching graph data");
            }     
        }
         finally {
            setIsFilterData(false);
            setDatesPreviousEndDate(endDate);
            setDatesPreviousStartDate(startDate);

            if (clonedData.type === 'default') {
                /*
                Dirty hack to keep filter data for the first (default) component.
                 */
                await saveFilters()
            }

            getLineChartData()
        }
    };

    const generateParams = () => {
        let lineChartParams = new URLSearchParams();

        // const formattedStartDate = dayjs(startDate).set('second', 0).format('YYYY-MM-DD');
        // const formattedEndDate = dayjs(endDate).set('second', 0).format('YYYY-MM-DD');

        // lineChartParams.append('start_date', formattedStartDate);
        // lineChartParams.append('end_date', formattedEndDate);
        lineChartParams.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        lineChartParams.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 0o0)));

        lineChartParams.append('timezone', userTimezone)

        if (reportType === 'salesPerformance') {
            geoName.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPerformance}geo[${index}]`, value);
            });
            affiliateName.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPerformance}affiliate_name[${index}]`, value);
            });
            offerName.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPerformance}funnel_name[${index}]`, value);
            });

            if (isSuperAdmin && superTenant) {
                superTenant?.toString() && lineChartParams.append('tenant', superTenant?.toString())
            } else {
                tenantId?.toString() && lineChartParams.append('tenant', tenantId?.toString())
            }

        } else if (reportType === 'salesPace') {
            geoName.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPace}geo[${index}]`, value);
            });
            affiliateName.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPace}affiliate_name[${index}]`, value);
            });
            offerName.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPace}funnel_name[${index}]`, value);
            });

            if (isSuperAdmin && superTenant) {
                superTenant?.toString() && lineChartParams.append('tenant', superTenant?.toString())
            } else {
                tenantId?.toString() && lineChartParams.append('tenant', tenantId?.toString())
            }
        }

        const displayFilter = []
        if (geoName.length) {
            displayFilter.push('geo')
        }
        if (affiliateName.length) {
            displayFilter.push('affiliate_name')
        }
        if (offerName.length) {
            displayFilter.push('funnel_name')
        }

        if (checkIfAtLeastOneFilterSelected()) {
            displayFilter.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes[reportType]}display_filter[${index}]`, value);
            });
        } else {
            lineChartParams.append(`${LineChartPrefixes[reportType]}display_filter[0]`, 'total');
        }

        
        return lineChartParams;
    };
    

    function checkIfAtLeastOneFilterSelected() {
            return (geoName?.length !== 0 || affiliateName?.length !== 0 || offerName?.length !== 0)
    }

    useEffect(() => {
        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            geoName?.sort().toString() !== clonedData.geo_name?.sort().toString() ||
            affiliateName?.sort().toString() !== clonedData.affiliate_name?.sort().toString() ||
            offerName?.sort().toString() !== clonedData.offer_name?.sort().toString() ||
            dateRange !== clonedData.date_range
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );

        if (valuesChanged) {
            setIconFillColor(themedColors.gray.bluish_light);
            setDisableButton(false);
            setButtonBackgroundColor(themedColors.gray.light);
            if (clonedData.type === 'default') {
                // saveFilters()
            }
        } else {
            setIconFillColor(themedColors.white);
            setButtonBackgroundColor(themedColors.blue.medium);
            setDisableButton(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoName, affiliateName, offerName, dateRange, startDate, endDate]);

    function generateDataToHash() {
        const dataToHash = {
            geos: geoName,
            affiliateName: affiliateName,
            offerName: offerName,
            startDate: startDate,
            endDate: endDate,
            crmType: crmType
        };
        return dataToHash;
    }

    useEffect(() => {
        if (!isInitialLoad.current) {
            getFilterData();
        } else {
            isInitialLoad.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superTenant]);

    const getLineChartData = async () => {
        try {
            setDataKeys(createDataKeys())

            setLegendGeoName(geoName);
            setLegendAffiliateName(affiliateName);
            setLegendOfferName(offerName);

                if (reportType === 'salesPerformance') {
                    setIsLineChartData(true);
                    const lineChartParams = generateParams();
                    const cachedData = Utils.instance().checkHash(generateDataToHash(), reportName);

                    if (cachedData) {
                        if (cachedData.current.length === 0 && cachedData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(cachedData, isToday);
                        
                        if (transformedData!.previous) {
                            setPreviousData(transformedData!.previous);
                        }
                        if (transformedData!.current) {
                            setCurrentData(transformedData!.current);
                        }
                    } else {
                        const response = await OdinForgeService.instance().getSalesPerformanceGraphData(lineChartParams, clonedReportId);
                        const responseData = response.data;
                        if (responseData.current.length === 0 && responseData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(responseData, isToday);
                        if  (!transformedData) {
                            return
                        }
                        
                        // const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(mockData, isToday);

                        if (transformedData!.previous) {
                            setPreviousData(transformedData!.previous);
                        }
                        if (transformedData!.current) {
                            setCurrentData(transformedData!.current);
                        }

                        createHash(generateDataToHash, response.data, reportName, response.cacheTime)
                        // Utils.instance().createHash(generateDataToHash(), response.data, reportName, response.cacheTime);
                    }
                } else if (reportType === 'salesPace') {
                    setIsLineChartData(true);
                    const lineChartParams = generateParams();
                    const cachedData = Utils.instance().checkHash(generateDataToHash(), reportName);

                    if (cachedData) {
                        if (cachedData.current.length === 0 && cachedData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(cachedData, isToday);
                        if  (!transformedData) {
                            return
                        }

                        if (transformedData.previous) {
                            setPreviousData(transformedData.previous);
                        }
                        if (transformedData.current) {
                            setCurrentData(transformedData.current);
                        }
                        if (crmType === 'swiss') {
                            checkDataForNaN(cachedData);
                        }
                    } else {
                        const response = await OdinForgeService.instance().getSaleGraphData(lineChartParams, clonedReportId);
                        const responseData = response.data;
                        if (responseData.current.length === 0 && responseData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(responseData, isToday);

                        if (transformedData!.previous) {
                            setPreviousData(transformedData!.previous);
                        }
                        if (transformedData!.current) {
                            setCurrentData(transformedData!.current);
                        }

                        createHash(generateDataToHash, response.data, reportName, response.cacheTime)
                        // Utils.instance().createHash(generateDataToHash(), response.data, reportName, response.cacheTime);

                        if (crmType === 'swiss') {
                            checkDataForNaN(responseData);
                        }
                    }
                }
                setIsLineChartData(false);
                setIsDataFromBE(true);
        } catch (error) {
            console.log('Error fetching line chart data', error);
            // @ts-ignore
            if (error?.code === 'ERR_CANCELED') {
                setLoaderKey(prevKey => prevKey + 1);
                console.log('Request canceled');
                
            } else {
                setIsDataFromBE(false);
                setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                Utils.instance().onFailure("There was an error fetching the graph data.");
            }
        } 
    };

    useEffect(() => {
        if (
            (startDate as Dayjs).format('YYYY-MM-DD') === (datesPreviousStartDate as Dayjs).format('YYYY-MM-DD') &&
            (endDate  as Dayjs).format('YYYY-MM-DD') === (datesPreviousEndDate  as Dayjs).format('YYYY-MM-DD')
        ) {
            setDatesApplyButton(true);
        } else {
            setDatesApplyButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
        if (componentCount === 0 && dateRange === 0) {
            getFilterData().then(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
       if (dateRange && dateRange !== 0) {
           getFilterData()
       }
       // dirty dirty dirty hack
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);



    const filterConfig = [
        { label: "Filter by Geo", name: "geoName", options: geoOptions, itemName: geoName, handleSelectChange: handleGeoSelectChange },
        { label: "Filter by Affiliate", name: "affiliateName", options: affiliateOptions, itemName: affiliateName, handleSelectChange: handleAffiliateSelectChange },
        { label: "Filter by Offer", name: "offerName", options: offerOptions, itemName: offerName, handleSelectChange: handleOfferSelectChange },
    ];

    const clearOptions = () => {
        setGeoName(emptyArray);
        setAffiliateName(emptyArray);
        setOfferName(emptyArray);
    }

    const getTooltipLabel = (reportType: string) => {
        if (reportType === 'salesPerformance') {
            return 'Clicks';
        } else if (reportType === 'salesPace') {
            return 'Sales';
        }
    }


    return (
        <div className='p-4 tablet-size:p-0'>
            {isFilterData && (
                <FilterBarChartSceleton title={reportName} />
            )}

            {!isFilterData && (
                <>
                    <FilterBarChart
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            generateMappedNames={geoName}
                            affiliateName={affiliateName}
                            offerName={offerName}
                            getLineChartData={getLineChartData}
                            passData={passData}
                            clonedReportId={clonedReportId}
                            passId={passId}
                            setDeletePopup={setDeletePopup}
                            deletePopup={deletePopup}
                            showDelete={showDelete}
                            datesApplyButton={datesApplyButton}
                            getFilterData={getFilterData}
                            copyButton={copyButton}
                            reportName={reportName}
                            disableButton={disableButton}
                            buttonBackgroundColor={buttonBackgroundColor}
                            setButtonBackgroundColor={setButtonBackgroundColor}
                            iconFillColor={iconFillColor}
                            setCopyButton={setCopyButton}
                            setIconFillColor={setIconFillColor}
                            setDisableButton={setDisableButton}
                            crmType={crmType}
                            setCrmType={setCrmType}
                            clonedData={clonedData}
                            enableFiltersApplyButton={enableFiltersApplyButton}
                            filterConfig={filterConfig}
                            title={reportName}
                            clearOptions={clearOptions}
                            isToggle={false}
                            // Refresh cloneData
                            setRefreshKey={setRefreshKey}
                            refreshKey={refreshKey}
                            // For datepicker
                            rangeConfigs={rangeConfigs}
                    />
                    {/* {
                        reportName === ReportName.SalesPerformanceReportLineChart && (
                            <ButtonIcon icon={<BackArrowIcon />} text={'Back'} onClick={handleNavigateBack} additionalStyle={'mt-5 pl-0 text-[#0079FF] text-[16px]'} />
                        )
                    } */}
                    
                </>
            )}

            {(isFilterData || isLineChartData) && (
                <div key={loaderKey} style={{ background: themedColors.background_1, borderColor: `${theme === 'dark' ? 'rgb(39, 51, 73)' : ''}` }} className={'mt-4 border rounded-lg h-96 w-full justify-center items-center flex tablet-size:-ml-2'}>
                    <TableLoading />
                </div>
            )}

            {!isFilterData && !isLineChartData && (
                <div className={'flex flex-row w-full pt-4 tablet-size:-ml-2'}>
                    <LineChart
                        currentData={currentData}
                        previousData={previousData}
                        dataKeys={dataKeys}
                        startDate={startDate}
                        endDate={endDate}
                        isDataFromBE={isDataFromBE}
                        geoName={legendGeoName}
                        affiliateName={legendAffiliateName}
                        offerName={legendOfferName}
                        errorMessage={errorMessage}
                        tooltipLabel={getTooltipLabel(reportType)}
                    />
                </div>
            )}
        </div>
    );
};

export default SalesPerformanceReportLineChart;
