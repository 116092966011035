import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import {ChevronRightIcon, ChevronDownIcon} from "@heroicons/react/outline";
import {tableCellClasses} from "@mui/material/TableCell";
import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import {Icon} from "@tremor/react";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import {borderColorEnum, darkBorderColorEnum, darkMainColorEnum, darkSubColorEnum, mainColorEnum, subColorEnum} from "../../../../types/enums/TableColors"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import firstRowData from "./data/firstRowData";
import ExclamationMark from "../../../../assets/icons/ExclamationMark";
import FilterBar from "../../../organism/filterbar/FilterBar";
import { transformData } from "./utils/transformData";
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";
import ScrollContainer from "react-indiana-drag-scroll";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { determineNestingLevels, reorderLevels, updateExpandedState, handleExpand, handleMainExpand } from "../../../../helpers/collapsingFunctions";
import { dateRangeList, fields, fieldsForCSV, generateRangeConfigs, namesEnum, rangeConfigs } from './utils/config'
import { rowBottomBorder } from "../../../../constants/rowBottomBorder";
import { sortData } from "../../../../helpers/sortData";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import SortedArrowAscending from "../../../../assets/icons/SortedArrowAscending";
import SortedArrowDescending from "../../../../assets/icons/SortedArrowDescending";
import { getRowHoverColor } from "../../../../helpers/rowHoverColor";
import CustomCell from "../../../UI/CustomCell/CustomCell";
import ReactCountryFlag from "react-country-flag"
import { hasFlag } from 'country-flag-icons'
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import formatRowName from "../../../../helpers/formatRowName";
import { AffiliateReportClonedData, AffiliateReportData, AffiliateReportRowInterface, NestedLevels } from "./types/affiliate-report.types";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { columnWidth, getFirsrColimnWidth, getLoadMoreBtnWidth } from "../../../../helpers/getFirstColumnWidth";
import LoadMoreButton from "../../../UI/LoadMoreButton/LoadMoreButton";
import searchRow from "../../../../helpers/searchRow";
import highlightMatch from "../../../../helpers/highlightMatch";

dayjs.extend(utc);
dayjs.extend(timezone);

type TipsHoveredState = {
    [index: number]: boolean;
};

interface props {
    names: string[]
    clonedData: AffiliateReportClonedData
    passData: (data: AffiliateReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}
const AffiliateSalesReport: React.FC<props> = ({
    names,
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}: props) => {
    const { themedColors, theme } = useContext(ThemeContext)!;
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
    const [selectedGroupings, setSelectedGroupings] = React.useState(3)
    const [collapse, setCollapse] = React.useState(clonedData['expand_level']?clonedData['expand_level']:'0');
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const params = new URLSearchParams();
    const [showTable, setShowTable] = React.useState(!!clonedData['data'])
    const [data, setData] = React.useState<AffiliateReportData | []>(clonedData['data']?clonedData['data']:[]);
    const expandLevel = collapse;
    const reportName = ReportName.AffiliateSalesSummary;
    const groupSequence =  groupings;
    const reportConfigValues = useRef({ expandLevel, reportName, groupSequence });
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    const [previousGroupings, setPreviousGroupings] = useState<string[]>([]);
    const [previousSuperTenant, setPreviousSuperTenant] = useState<string | null>(superTenant);
    const [previousStartDate, setPreviousStartDate] = useState(startDate);
    const [previousEndDate, setPreviousEndDate] = useState(endDate);

    const isMobile = useMediaQuery('(max-width: 1023px)')

    let transformedGroupings = ['Currency', ...groupings];

    const [nestedLevels, setNestedLevels] = useState<NestedLevels>({});
    const [sortState, setSortState] = useState(0);
    const [sortField, setSortField] = useState<string | null>(null);
    const [originalData, setOriginalData] = useState<AffiliateReportData | []>(clonedData['data']?clonedData['data']:[])

    const [isHoveredRow, setIsHoveredRow] = useState<number | null | string>(null);

    const isInitialLoadCollapse = useRef(true);

    const isInitialLoad = useRef(true);

    const [page, setPage] = useState({
        0: 1,
        1: 1,
        2: 1,
        3: 1,
    });
    const [loaderKey, setLoaderKey] = useState(0);

    const [ isSearchResult, setIsSearchResult ] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        if (data && collapse !== undefined) {
            const updatedLevels = updateExpandedState(nestedLevels, Number(collapse));
            setNestedLevels(updatedLevels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse, data]);

    useEffect(() => {
        if (dateRange !== 0) handleBlur()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    function newTable(row: AffiliateReportRowInterface, groupIndex: number, parents: string[]) {
        if ((namesEnum)[transformedGroupings[groupIndex+1]] in row){
            return GetTableBody(row[(namesEnum)[transformedGroupings[groupIndex+1]]], groupIndex+1, parents)
        }
        else
            return  null;
    }

    function GetSubTable(row: AffiliateReportRowInterface, index: number, borderColor: string, groupIndex: number, parents: string[], rowCount: number) {
        const staticParents = parents.slice()
        staticParents.push(row.name)

        const classNameBorderBottom = rowBottomBorder

        const isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded

        const isHidden = index + 1 > page[groupIndex as keyof typeof page]*10


        if (isHidden) {
            return null
        }

        const isLastRow = rowCount === (index + 1)

        return (
            <Table key={index}>
                <TableBody>
                    <TableRow 
                        style={{ padding:0, height:42 }} 
                    >
                            <TableCell  style={{fontSize:'12px',padding:'0px 8px 0px 0px', width:(columnWidth as { [key: number]: number })[selectedGroupings]+52, backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme) }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}>
                            </TableCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'  }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['clicks']}></CustomCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'  }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['clicks_with_orders']}></CustomCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['partials']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['partials_percentage']}></CustomCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['declines']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['declines_percentage']}></CustomCell>
                            <CustomCell type='item'  style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['sales']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['conv_percentage']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '150px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['partial_conv']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['sales_conv']}></CustomCell>
                            <CustomCell type='currency' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end' }} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['sales_rev']} currency={row['currency']}></CustomCell>                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={13} style={{padding:0}}>
                            <Collapse in={
                                    isExpanded
                                }
                                 timeout={'auto'} unmountOnExit>
                                <Table style={{padding:0}} sx={{
                                    "& tr:first-of-type td:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                }}>
                                    {newTable(row, groupIndex, staticParents)}
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
                {!isLastRow && <TableRow 
                    className={"h-[42px] flex w-0"}
                    style={{
                        display: (index + 1 === page[groupIndex as keyof typeof page]*10) ? 'table' : 'none',
                    }}
                ></TableRow>}
            </Table>
        )
    }

    function GetTableBody(temp1:AffiliateReportRowInterface[], groupIndex: number, parents: string[]) {
        const staticData:AffiliateReportRowInterface[] = temp1;
        const color = theme === 'light' ? borderColorEnum[groupIndex] : darkBorderColorEnum[groupIndex]

        const name = transformedGroupings[groupIndex]

        const isExpanded = nestedLevels[namesEnum[name]]?.expanded

        return <>
            <TableBody>
                <TableRow style={{fontSize:'12px', backgroundColor:  theme === 'light' ? mainColorEnum[groupIndex] : darkMainColorEnum[groupIndex], borderRadius:5}}>
                    <TableCell align={'left'} colSpan={13} style={{padding:0}}>
                    <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {handleMainExpand(namesEnum[name], setNestedLevels)
                            }}
                        >
                            {isExpanded ? <Icon icon={ChevronDownIcon} style={{color:'white'}}/> : <Icon icon={ChevronRightIcon} style={{color:'white'}}/>}
                        </IconButton>
                        <span style={{fontSize:'12px',}} className={'text-white font-bold'}>{name}</span>
                    </TableCell>
                </TableRow>
                <TableRow style={{backgroundColor: theme === 'light' ? subColorEnum[groupIndex] : darkSubColorEnum[groupIndex]}}>
                    <TableCell colSpan={13} style={{padding:0}}>
                        <Collapse in={
                                isExpanded
                            } timeout={'auto'} unmountOnExit>
                            {staticData?.map((row:AffiliateReportRowInterface, index:number) => (
                                GetSubTable(row,index,color,groupIndex, parents, temp1.length)
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>;
    }

    function newFirstColumn(row: AffiliateReportRowInterface, groupIndex: number) {
        if ((namesEnum)[transformedGroupings[groupIndex+1]] in row){
            return GetFirstColumn(row[(namesEnum)[transformedGroupings[groupIndex+1]]], groupIndex+1)
        }
        else
            return  null;
    }

    function GetFirstColumnNames(row: AffiliateReportRowInterface, index: number, borderColor: string, groupIndex: number, rowCount: number) {
        let classNameBorderBottom = ``
        let isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded

        let isNotNeededBorder = groupIndex === selectedGroupings - 1 ? true : false

        if (isNotNeededBorder) {
            if (isExpanded) {
                classNameBorderBottom = rowBottomBorder
            } else {
                classNameBorderBottom = rowBottomBorder
            }
        } else {
            if (isExpanded) {
                classNameBorderBottom = ''
            } else {
                classNameBorderBottom = rowBottomBorder
            }
        }

        const isHidden = index + 1 > page[groupIndex as keyof typeof page]*10

        if (isHidden) {
            return null
        }

        const isLastRow = rowCount === (index + 1)

        const cellWidth = getFirsrColimnWidth(selectedGroupings, groupIndex);
        const charCount = Math.floor(Number(cellWidth.replace('px', ''))! / 7);
        const rowName = formatRowName(row.name, row.currency, groupIndex)

        const loadMoreBrnContainerWidth = getLoadMoreBtnWidth(selectedGroupings, groupIndex);

        function getIsTooltop() {
            return rowName?.length > charCount  ? rowName : '';
        }

        return (
            <Table key={index}>
                <TableBody>
                    <TableRow style={{ padding:0}}>
                    <Tooltip 
                            title={ getIsTooltop()}
                            placement={isMobile ? "bottom-start" : "left"}
                            classes={{
                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                              }}
                            arrow
                            enterTouchDelay={10}
                            leaveTouchDelay={1000}
                        >
                            <TableCell
                                style={{
                                    fontSize: '12px',
                                    padding: '0px',
                                    width: 200,
                                    borderTopLeftRadius: 0,
                                    backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme),
                                    cursor: groupIndex !== transformedGroupings.length - 1 ? 'pointer' : 'default'
                                }}
                                className={classNameBorderBottom}
                                onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}
                                onClick={() => {
                                    handleExpand(row?.id, groupIndex + 1, setNestedLevels)
                                }}
                            >
                                        <div className={'flex flex-row flex-nowrap text-nowrap'} >
                                            {
                                                groupIndex !== transformedGroupings.length - 1 ? (
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        disabled={true}
                                                    >
                                                    
                                                        { 
                                                        nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded ? 
                                                            <Icon icon={ChevronDownIcon} className={"!text-black dark:!text-odin-dark-content"}/> : 
                                                            <Icon icon={ChevronRightIcon} className={"!text-black dark:!text-odin-dark-content"}/>}
                                                    </IconButton>
                                                ) : (
                                                    <span className="h-[42px] w-2" >
                                                    </span>
                                                )
                                            }
                                            <span className={hasFlag(row.name)?'pt-3 pr-3':'pt-3 hidden'}>
                                                {
                                                    hasFlag(row.name) ? <ReactCountryFlag countryCode={row.name} svg style={{ width: '18px', height: '16px', }} /> : null
                                                } 
                                            </span>
                                            <span
                                                style={{
                                                    width: cellWidth,
                                                    fontSize: '12px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                                className={'pt-[15px] leading-3 dark:text-odin-dark-content'}
                                                >
                                                    {
                                                        highlightMatch(rowName, searchTerm, theme)
                                                    }
                                                </span>
                                        </div>
                            </TableCell>
                        </Tooltip>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{padding:0}}>
                            <Collapse in={
                                    (nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded)
                                } timeout={'auto'} unmountOnExit>

                                <Table style={{padding:0,marginLeft:36, minWidth:150}} sx={{
                                    "& tr:first-of-type td:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                }}>
                                    {newFirstColumn(row, groupIndex)}
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
                <div style={{width: loadMoreBrnContainerWidth}}>
                    {!isLastRow && <LoadMoreButton groupIndex={groupIndex} index={index} page={page} setPage={setPage} isHoveredRow={isHoveredRow} setIsHoveredRow={setIsHoveredRow} />}
                </div>
            </Table>
        )
    }

    function GetFirstColumn(temp1:AffiliateReportRowInterface[], groupIndex: number) {
        const data:AffiliateReportRowInterface[] = temp1;
        const color = theme === 'light' ? borderColorEnum[groupIndex] : darkBorderColorEnum[groupIndex]
        const isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.expanded

        return <>
            <TableBody>
                <TableRow style={{fontSize:'12px', backgroundColor: theme === 'light' ? mainColorEnum[groupIndex] : darkMainColorEnum[groupIndex], borderRadius:5}}>
                    <TableCell align={'left'} style={{padding:0, cursor: groupIndex !== 0 ? 'pointer' : 'default' }} 
                        onClick={groupIndex !== 0 ? () => {handleMainExpand(namesEnum[transformedGroupings[groupIndex]], setNestedLevels)} : () => {}}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            disabled={true}
                        >   
                   
                            {groupIndex !== 0 ? isExpanded ? <Icon icon={ChevronDownIcon} className={"!text-white dark:text-odin-dark-content"}/> : <Icon icon={ChevronRightIcon} className={"!text-white dark:text-odin-dark-content"}/> : <div className="h-8" />}
                        </IconButton>
                        <span style={{fontSize:'12px',}} className={'text-white font-bold'} id={transformedGroupings[groupIndex]}>{transformedGroupings[groupIndex]}</span>
                    </TableCell>
                </TableRow>
                <TableRow style={{backgroundColor: theme === 'light' ? subColorEnum[groupIndex] : darkSubColorEnum[groupIndex]}}>
                    <TableCell style={{padding:0}}>
                        <Collapse in={
                                isExpanded
                            } timeout={'auto'} unmountOnExit>
                            {data?.map((row:AffiliateReportRowInterface, index:number) => (
                                GetFirstColumnNames(row,index,color,groupIndex, temp1.length)
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>;
    }

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        collapse: collapse,
        dateRange: dateRange
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            collapse !== clonedData['expand_level']
            || JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { expandLevel, reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.collapse !== collapse ||
            previousValues.dateRange !== dateRange
        )) {
            handleBlur();
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                collapse: collapse,
                dateRange: dateRange
            };
        }

        setSearchTerm('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, collapse, reportName, dateRange, startDate, endDate]);

    useEffect(() => {
        if (dateRange === 0) {
            handleBlur()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings]);

    useEffect(() => {
        previousValuesRef.current = {
            groupings: JSON.stringify(groupings),
            collapse: collapse,
            dateRange: dateRange
        };
    }, [groupings, collapse, reportName, dateRange, theme]); 

    function generateParams() {
        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));

        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        filters.forEach((value, index) => {
            params.append(`filters[${index}]`, value)
        })
        params.append('timezone', timezone) 
        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
        }

        return dataToHash;
    }

    const isDataSame = (groupings: string[], previousGroupings: string[], startDate: string | Dayjs, previousStartDate: string | Dayjs, superTenant: string | null = null, previousSuperTenant: string | null = null) => {
        if (!isSuperAdmin) {
            return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate);
        }
        return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && superTenant === previousSuperTenant);
    };

    useEffect(() => {
        if (!isInitialLoad.current) {
            handleBlur();
        } else {
            isInitialLoad.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superTenant]);

    async function handleBlur() {
        if (tenantId === null) return;
        setShowTable(false);
        setTableLoading(true);
        if (groupings.length === 0) {
            setTableLoading(false);
            return;
        }
        generateParams()
        if (!isDataSame(groupings, previousGroupings, startDate, previousStartDate, superTenant, previousSuperTenant)) {
            setPage({
                0: 1,
                1: 1,
                2: 1,
                3: 1,
            })
            setData([]);
            setOriginalData([]);
            const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
            if (cachedData){
                setTableLoading(false);
                const transformedData = await transformData(cachedData);
                setData(transformedData)
                setOriginalData(transformedData)
                setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)))

                setSelectedGroupings(transformedGroupings.length);
                if (Number(collapse)>transformedGroupings.length){
                    setCollapse((transformedGroupings.length).toString())
                }
                setShowTable(true);
                
                // It is for expanding the table to the last level
                if (!isInitialLoadCollapse.current) {
                    setCollapse(transformedGroupings.length.toString());
                } else {
                    isInitialLoadCollapse.current = false;
                }
            } else {
                OdinForgeService.instance().getAffiliateData(params, clonedReportId)
                    .then(async (result: {data: AffiliateReportData, cacheTime: number}) => {
                        setTableLoading(false);
                        const transformedData = await transformData(result.data);

                        if (result.data[(namesEnum)[(transformedGroupings)[0]]] && (result.data[(namesEnum)[(transformedGroupings)[0]]]! as []).length !== 0){
                            setData(transformedData);
                            setOriginalData(transformedData)
                            setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)))

                            setSelectedGroupings(transformedGroupings.length);
                            if (Number(collapse)>transformedGroupings.length){
                                setCollapse((transformedGroupings.length).toString())
                            }
                            setShowTable(true);

                            createHash(generateObjectToHash, result.data, reportName, result.cacheTime)

                            // It is for expanding the table to the last level
                            if (!isInitialLoadCollapse.current) {
                                setCollapse(transformedGroupings.length.toString());
                            } else {
                                isInitialLoadCollapse.current = false;
                            }
                            // Utils.instance().createHash(generateObjectToHash(), result.data, reportName, result.cacheTime)
                        } else {
                            setTableLoading(false);
                            setData([]);
                            setOriginalData([]);
                            // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                        }
                    })
                    .catch((error) => {
                        if (error.code === 'ERR_CANCELED') {
                            setLoaderKey(prevKey => prevKey + 1);
                            console.log('Request canceled');
                        } else {
                            setTableLoading(false);
                            Utils.instance().onFailure('An error occurred while fetching the report');
                        }
                    });
            }
        } else {
            if (data.length!==0)
                setShowTable(true)
            setTableLoading(false);
        }
        setPreviousGroupings(groupings);
        setPreviousStartDate(startDate)
        setPreviousEndDate(endDate)
        setPreviousSuperTenant(superTenant);
    }

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                expand_level: collapse,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            },
            report_name: reportName
        }
    }

    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            expand_level: collapse,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
        }
        passData(dataToPass)
    }

    function FirstRow() {
        const [tipsHovered, setTipsHovered] = useState<TipsHoveredState>({});
        const [tooltipOpen, setTooltipOpen] = useState<number | null>(null);
    
        const handleMouseEnter = (index: number) => {
            if (!isMobile) {
                setTipsHovered(prev => ({ ...prev, [index]: true }));
            }
        };
    
        const handleMouseLeave = (index: number) => {
            if (!isMobile) {
                setTipsHovered(prev => ({ ...prev, [index]: false }));
            }
        };
    
        const handleTooltipToggle = (index: number) => {
            if (isMobile) {
                setTooltipOpen(prev => (prev === index ? null : index));
            }
        };
    
        return (
            <>
                {
                    firstRowData.map((row, index) => (
                        <TableCell key={index} style={{ fontSize: row.fontSize, width: row.width, padding: row.padding }} className={`!leading-3 ${sortField === fields[row.name as keyof typeof fields] && (sortState !== 0 ? 'bg-[#CEE5FC] dark:bg-slate-800' :  '')}`}>
                            <div className="flex gap-2 items-center">
                                <span 
                                    className={`text-[#677C9F] ${showTable && row.hasSortField ? "cursor-pointer hover:text-[#242546] dark:hover:text-[#EBEFF7]" : ''} flex gap-2 items-center w-full justify-end`} 
                                    onClick={showTable && row.hasSortField ? () => sortTableData(fields[row.name as keyof typeof fields]) : undefined}
                                >
                                    {row.name}
                                </span>
                                {
                                    row.isTips && (
                                        <Tooltip 
                                            classes={{ 
                                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal !bg-opacity-90 !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                             }}
                                            title={row.tips}
                                            placement="right-end"
                                            open={isMobile ? tooltipOpen === index : undefined}
                                        >
                                            <span
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={() => handleMouseLeave(index)}
                                                onClick={() => handleTooltipToggle(index)}
                                            >
                                                <ExclamationMark isTipsHovered={!!tipsHovered[index]} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                {sortField === fields[row.name as keyof typeof fields] && (sortState === 1 ? <SortedArrowAscending /> : sortState === 2 ? <SortedArrowDescending /> : '')}
                            </div>
                        </TableCell>
                    ))
                }
            </>
        )
    }

    const sortTableData = (field: string) => {
        if (field !== sortField) {
          setSortField(field);
          setSortState(1);
          const sortedData = sortData(filteredData, field, 1, namesEnum);
          setFilteredData(sortedData);
        } else {
          const nextState = (sortState + 1) % 3;
          setSortState(nextState);
          let sortedData = filteredData;
          if (nextState === 0) {
            sortedData = originalData;
          } else {
            sortedData = sortData(filteredData, field, nextState, namesEnum);
          }
          setFilteredData(sortedData);
        }
    };

    const handleSearch = (isReset = false) => {
        setIsSearchResult(true)
        setShowTable(true)
        setPage({
                0: 1,
                1: 1,
                2: 1,
                3: 1,
            });
        const searchString = !isReset ? "" : searchTerm
        const result = searchRow(data,searchString);
        if (!result) {
            setShowTable(false);
            setIsSearchResult(false)
        }
        setOriginalData(result);
        setSortState(0);
        setFilteredData(result);
    };

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    return (
        <div className={"pb-10"} >
            <div className="!p-6 tablet-size:!p-0">
            <FilterBar
                handleBlur={handleBlur}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                groupings={groupings}
                setGroupings={setGroupings}
                collapse={collapse}
                setCollapse={setCollapse}
                selectedGroupings={selectedGroupings}
                copyButton={copyButton}
                freezeButton={freezeButton}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                names={names}
                title={ReportName.AffiliateSalesSummary}
                isAdditionalCurrencyField={true}
                transformedGroupings={transformedGroupings}
                maxSelections={3}
                // for CSV btn
                data={data}
                fieldsForCSV={fieldsForCSV}
                disabledCSV={!showTable}
                namesEnum={namesEnum}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                // For datepicker
                rangeConfigs={rangeConfigs}
                generateRangeConfigs={generateRangeConfigs}
                dateRangeList={dateRangeList}
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                // SearchRowProps
                isSearch={true}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
            />
            </div>

            <ScrollContainer className="scroll-container" hideScrollbars={false} mouseScroll={{rubberBand: false}} style={{cursor: 'default'}}>
            <div className={`pt-0 tablet-size:pt-6  rounded-3xl ${isMobile && 'relative'} safari-relative`} >
                <div className={'absolute left-0 tablet-size:left-6 z-10 overflow-hidden shadow-[4px_0_8px_-5px_rgba(0,0,0,0.25)] safari-left-zero'} style={{width:(columnWidth as { [key: number]: number })[selectedGroupings]}}>
                    {showTable && filteredData ?
                    (<>
                        <TableContainer style={{overflowX:'hidden'}} >
                            <Table sx={{
                                "& .MuiTableRow-root th:first-of-type": {
                                    borderTopLeftRadius: "10px",
                                },
                                "& .MuiTableRow-root th:last-of-type": {
                                    borderTopRightRadius: "10px",
                                },
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none"
                                }
                            }}>
                                <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content"}>
                                    <TableRow>
                                        <TableCell style={{fontSize:'12px', padding: '16px 8px 16px 8px', position:'sticky', color: themedColors.gray.bluish}}>Groupings</TableCell>
                                    </TableRow>
                                </TableHead>
                                {GetFirstColumn((filteredData as AffiliateReportRowInterface)[(namesEnum)[transformedGroupings[0]]], 0)}
                            </Table>
                        </TableContainer>
                    </>)
                    : null}</div>
                <TableContainer className={''} style={{minWidth:(showTable?1800:0), width:selectedGroupings>5?'120%':'100%', overflow:'hidden'}}>
                    <Table className={''} sx={{
                        "& .MuiTableRow-root th:first-of-type": {
                            borderTopLeftRadius: "10px",
                        },
                        "& .MuiTableRow-root th:last-of-type": {
                            borderTopRightRadius: "10px",
                        },
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        }
                    }}>
                        <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]"} style={{borderRadius: 5}}>
                            <TableRow>
                                <TableCell style={{fontSize:'12px', height: '52px', width: (columnWidth as { [key: number]: number })[selectedGroupings]+52, padding: '16px 8px 16px 8px', color: themedColors.gray.bluish}} >{showTable ? 'Groupings' : ''}</TableCell>
                                {showTable && <FirstRow />}
                            </TableRow>
                        </TableHead>
                        {showTable && filteredData ?
                            (<>
                                {GetTableBody((filteredData as AffiliateReportRowInterface)[(namesEnum)[transformedGroupings[0 % selectedGroupings]]], 0, [])}
                            </>)
                            : null}
                    </Table>
                </TableContainer>
                {!showTable ? 
                    <DefaultTableMessage
                        key={loaderKey}
                        tableLoading={tableLoading}
                        state={
                            groupings.length === 0 ? 
                            'noFilters' :
                            !isSearchResult ? 'emptySearchResult' :
                            'noData'
                        }
                    />
                    : null
                }
            </div>
            </ScrollContainer>
        </div>
    );
}

export default AffiliateSalesReport;


