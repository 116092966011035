import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import { BaseDashboard } from "./components/organism/BaseDashboard/BaseDashboard";
import { LoginController } from "./components/pages/login/LoginController";
import 'react-toastify/dist/ReactToastify.css';
import React, { useContext, useEffect } from 'react';
import PrivateRoute from "./components/pages/login/PrivateRoute";
import { Alert } from "./components/UI/alerts/Alert";
import { EmptyReport } from "./components/pages/reports/salesPaceReport/EmptyReport";
import { LoadingProvider } from "./components/UI/loading/LoadingContext";
import ProfileInfo from "./components/pages/profile/ProfileInfo";
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { BaseTransactionReport } from "./components/pages/reports/transactionPerformanceReport/BaseTransactionReport";

import AffiliateSalesBase from "./components/pages/reports/AffiliateSalesReport/AffiliateSalesBase";
import { SelectedItemProvider } from "./components/organism/sidebar/SelectedItemContext";
import { SideBarClickProvider } from "./components/organism/sidebar/SideBarClickContext";
import { SessionExpirationProvider } from "./components/UI/sessionExpiryComponent/SessionExpirationContext";
import { ForgotPassword } from "./components/pages/login/ForgotPassword";
import SalesPerformanceBase from "./components/pages/reports/SalesPerformanceReport/SalesPerformanceBase";
import { BaseRetentionReport } from "./components/pages/reports/retentionReport/BaseRetentionReport";
import { BaseCancellationReasons } from "./components/pages/reports/cancellationReasons/BaseCancellationReasons";
import { BaseForecastedRebills } from "./components/pages/reports/forecastedRebills/BaseForecastedRebills";
import LineChartBase from "./components/pages/reports/LineChart/LineChartBase";
import { RemoveTrailingSlash } from './router/TrailingSlash';
import VarianceBase from "./components/pages/reports/VarianceReport/VarianceBase";
import { VarianceReportProvider } from "./components/pages/reports/VarianceReport/VarianceContext";
import BaseDashboardOverview from './components/pages/reports/dashboardOverview/BaseDashboardOverview';
import { UserProvider } from "./context/UserContext/UserContext";
import ProtectedReport from "./components/organism/protectedReport/ProtectedReport";
import { ThemeProvider, ThemeContext } from './context/ThemeContext/ThemeContext';
import getDesignTokens from './mui.config';

import NotFoundPage from './components/pages/NotFoundPage/NotFoundPage';
import { getCookie } from './helpers/getCookie';
import BaseConversationReport from './components/pages/reports/conversationReport/ConversationReportBase';
import { ResetPassword } from './components/pages/login/ResetPassword';
import AccessDeniedPage from './components/pages/AccessDeniedPage/AccessDeniedPage';
import shouldHideForProductionWebsite from './helpers/shouldHideForProductionWebsite';
import DeclineResponseBase from './components/pages/reports/DeclineResponseReport/DeclineResponseBase';
import BaseVarianceReport from './components/pages/reports/VarianceReport2/VarianceReportBase';
import { ModalProvider } from './context/BanContext/BanContext';
import { BaseCancellationSubscriptionReasons } from './components/pages/reports/cancellationSubscriptionReport/BaseCancellationSubscriptionReport';
import BinRoutingPerformaceReportBase from './components/pages/reports/BinRoutingPerformaceReport/BinRoutingPerformaceReportBase';

dayjs.extend(utc);
dayjs.extend(timezone);

const App: React.FC = () => {
    return (
        <ThemeProvider>
            <BrowserRouter>
                <UserProvider>
                    <ModalProvider>
                        <ThemedApp />
                    </ModalProvider>
                </UserProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;

const ThemedApp = () => {
    const { theme } = useContext(ThemeContext)!;

    const darkModeTheme = createTheme(getDesignTokens(`${theme}`));

    // create useEffect to set background for html depends on theme from coocies
    useEffect(() => {
        document.documentElement.style.backgroundColor = theme === 'dark' ? '#17202E' : '#FFFFFF';
    }
        , [theme]);

    const changeTheme = (isDark: boolean) => {
        if (isDark) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    };

    useEffect(() => {
        const theme = getCookie('theme');
        changeTheme(theme === 'dark');
    }, [theme]);

    return (
        <MuiThemeProvider theme={darkModeTheme}>
            <LoadingProvider>
                <div className={`h-full ${theme === 'dark' && 'dark bg-odin-dark-blue-dark'}`}>
                    <Alert />
                    <SessionExpirationProvider>
                        <div id="modal-root"></div>
                        <RemoveTrailingSlash />
                        <Routes>
                            <Route path={"forgot-password"} element={<ForgotPassword />} />
                            <Route path={"password-reset"} element={<ResetPassword />} />
                            <Route path="/" element={<LoginController />} />
                            <Route path="/access-denied" element={<AccessDeniedPage />} />
                            <Route path="/dashboard" element={
                                <SelectedItemProvider>
                                    <SideBarClickProvider> {/* Wrap with SideBarClickProvider */}
                                        <PrivateRoute component={BaseDashboard} />
                                    </SideBarClickProvider>
                                </SelectedItemProvider>}>
                                <Route path={""} element={<ProtectedReport />}>
                                    <Route
                                        path="/dashboard/sales-pace-report-table"
                                        element={<EmptyReport />}
                                    />
                                    <Route
                                        path="/dashboard/sales-pace-report-line-chart"
                                        element={<LineChartBase reportType={'salesPace'} />}
                                    />
                                    <Route
                                        path="/dashboard/sales-performance-report"
                                        element={<SalesPerformanceBase />}
                                    />
                                    {/* <Route
                                        path="/dashboard/sales-performance-report/sales-performance-report-line-chart"
                                        element={<LineChartBase reportType={'salesPerformance'} />}
                                    /> */}
                                    <Route
                                        path="/dashboard/sales-performance-report-line-chart"
                                        element={<LineChartBase reportType={'salesPerformance'} />}
                                    />
                                    <Route
                                        path="/dashboard/transaction-performance-report"
                                        element={<BaseTransactionReport />}
                                    />
                                    <Route
                                        path={"/dashboard/affiliate-sales-summary"}
                                        element={<AffiliateSalesBase />}
                                    />
                                    <Route
                                        path="/dashboard/profileInfo"
                                        element={<ProfileInfo />}
                                    />
                                    <Route
                                        path="/dashboard/retention-report"
                                        element={<BaseRetentionReport />}
                                    />
                                    <Route
                                        path="/dashboard/cancellationReasons"
                                        element={<BaseCancellationReasons />}
                                    />
                                    <Route
                                        path="/dashboard/cancellationSubscriptionReasons"
                                        element={<BaseCancellationSubscriptionReasons />}
                                    />
                                    <Route
                                        path="/dashboard/forecasted-rebills-report"
                                        element={<BaseForecastedRebills />}
                                    />
                                    <Route
                                        path="/dashboard/conversion-report"
                                        element={<BaseConversationReport />}
                                    />
                                    <Route
                                        path="/dashboard/decline-response-report"
                                        element={<DeclineResponseBase />}
                                    />
                                    {/* {!shouldHideForProductionWebsite() && (
                                        <Route
                                            path="/dashboard/variance-report"
                                            element={<BaseVarianceReport />}
                                        />
                                    )} */}
                                     {/* {!shouldHideForProductionWebsite() && (
                                        <Route
                                            path="/dashboard/dashboard-overview"
                                            element={<BaseDashboardOverview />}
                                        />
                                    )} */}
                                    {/* {
                                        process.env.REACT_APP_ENABLE_MOCKED_PAGES === 'true' && (
                                            <Route
                                                path="variance-report"
                                                element={<VarianceReportProvider><VarianceBase /></VarianceReportProvider>}
                                            />
                                        )
                                    }
                                    {
                                        process.env.REACT_APP_ENABLE_MOCKED_PAGES === 'true' && (
                                            <Route
                                                path="/dashboard/dashboard-overview"
                                                element={<BaseDashboardOverview />}
                                            />
                                        )
                                    } */}
                                    <Route
                                        path="/dashboard/bin-routing-performance-report"
                                        element={<BinRoutingPerformaceReportBase />}
                                    />
                                </Route>
                            </Route>
                            {/* Next route shold be the last */}
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </SessionExpirationProvider>
                </div>
            </LoadingProvider>
        </MuiThemeProvider>
    );
}
