import { TableRow } from '@mui/material';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { getRowHoverColor, getRowHoverColorSalesPaceReport } from '../../../helpers/rowHoverColor';
import { isHoveredRowInterface } from '../../pages/reports/salesPaceReport/PaceReport';

type IsHoveredRowType = string | number | null | isHoveredRowInterface;

interface LoadMoreButtonProps {
  groupIndex: number;
  index: number;
  page: {
    0: number;
    1: number;
    2: number;
    3: number;
  };
  setPage: React.Dispatch<React.SetStateAction<{
    0: number;
    1: number;
    2: number;
    3: number;
  }>>;
  isHoveredRow: IsHoveredRowType;
  setIsHoveredRow: any;
  rowIndex?: string;
}

const LoadMoreButton = ({
  groupIndex,
  index,
  page,
  setPage,
  isHoveredRow,
  setIsHoveredRow,
  rowIndex = '',
}: LoadMoreButtonProps) => {
  const { theme } = useContext(ThemeContext)!;

  function isIsHoveredRowInterface(obj: any): obj is isHoveredRowInterface {
    return obj && typeof obj === 'object' && 'id' in obj && typeof obj.id === 'string';
  }

  const handleClick = () => {
    setPage((prev) => {
      return { ...prev, [groupIndex]: prev[groupIndex as keyof typeof page] + 1 };
    });
  }

  return (
    <TableRow
      className={'h-[42px] flex w-full justify-center items-center cursor-pointer text-xs'}
      style={{
        display: index + 1 === page[groupIndex as keyof typeof page] * 10 ? 'flex' : 'none',
        backgroundColor: isIsHoveredRowInterface(isHoveredRow)
          ? getRowHoverColorSalesPaceReport(isHoveredRow, rowIndex, theme, 4)
          : getRowHoverColor(isHoveredRow, (4+groupIndex).toString(), theme),
      }}
      onClick={handleClick}
      onMouseEnter={() => {
        setIsHoveredRow(
          isIsHoveredRowInterface(isHoveredRow)
            ? { index: rowIndex, rowNumber: 4 }
            : (4+groupIndex).toString()
        );
      }}
      onMouseLeave={() => setIsHoveredRow('4')}
    >
      Load more...
    </TableRow>
  );
};

export default LoadMoreButton;
